import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authStatus, setAuthStatus] = useState(null);
  const [subdomain, setSubdomain] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const host = window.location.host;
    const currentSubdomain = host.includes('.')
      ? host.split('.')[0]
      : null; 

    setSubdomain(currentSubdomain);

    const token = localStorage.getItem('authToken');
    setAuthStatus(token ? 'authenticated' : 'unauthenticated');
  }, [location]);

  return (
    <AuthContext.Provider value={{ authStatus, subdomain }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

export const SubdomainMiddleware = ({ children, allowedSubdomains }) => {
  const { subdomain } = useAuth();
  let sub = subdomain;
  if(!sub){
    const host = window.location.host;
    const currentSubdomain = host.includes('.')
      ? host.split('.')[0]
      : null;
      sub = currentSubdomain
  }

  if (allowedSubdomains.includes(sub)) {
    return <>{children}</>;
  }else{
    window.location.href = '/404'
  }


  // return (
  //   <div className="text-center text-red-500">
  //     Access Denied: Invalid Subdomain
  //   </div>
  // );
};

export const AuthStatusMiddleware = ({ children, requiredStatus }) => {
  const { authStatus } = useAuth();

  if (authStatus === requiredStatus) {
    return <>{children}</>;
  }else{
    window.location.href = '/login'
  }

  // return (
  //   <div className="text-center text-red-500">
  //     Access Denied: Invalid Authentication Status
  //   </div>
  // );
};
